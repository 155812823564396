import { FC } from 'react'
import { Link } from 'react-scroll'
import { nav } from '../constants/nav'

interface NavigationDotsProps {
  active: string
}

const NavigationDots: FC<NavigationDotsProps> = ({ active }) => {
  return (
    <div className='hidden absolute top-1/2 bg-gray-100 border-l border-t border-b border-gray-200 rounded-tl-xl rounded-bl-xl -translate-y-1/2 right-0 sm:flex flex-col justify-center items-center py-3 px-2'>
      {nav.map((navItem, index) => (
        <Link
          to={navItem.name}
          spy={true}
          href={navItem.name}
          smooth={true}
          duration={500}
          key={navItem.name + index}
          className='w-2.5 h-2.5 rounded-full bg-[#cbcbcb] m-2 transition hover:bg-indigo-500 cursor-pointer'
          style={active === navItem.name ? { backgroundColor: '#313bac' } : {}}
        />
      ))}
    </div>
  )
}

export default NavigationDots
