// import { format } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { FC, useEffect, useRef, useState } from 'react'
import { HiArrowLeft, HiChevronRight } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import * as ROUTES from '../../../constants/routes'
import { RootState } from '../../../redux/store'
import { getStepAnimationProps } from '../../../services'
import ComponentWrapper from '../../wrapper/ComponentWrapper'

const DynamicTimes = dynamic(() => import('../../CalendarComponent/Times'))
const DynamicLoginForm = dynamic(() => import('./LoginForm'))
const DynamicCalendar = dynamic(() => import('../../CalendarComponent/Calendar'))
const DynamicProgressCircles = dynamic(() => import('../../ProgressCircles'))

interface HeroProps {}

const Hero: FC<HeroProps> = ({}) => {
  // Hooks
  const selectedDate = useSelector((state: RootState) => state.calendarAndTime.selectedDate)
  const selectedTime = useSelector((state: RootState) => state.calendarAndTime.selectedTime)

  // Utility
  const clamp = (num: number) => Math.min(Math.max(num, 0), steps.length - 1)

  // Local State Definitions
  const [step, setStep] = useState(0)

  /**
   * Handlers for Landing Page appointment booking actions
   */

  // Keep track of current step & note chosen time & date in localStorage for easy booking after login
  useEffect(() => {
    const keepTrackOfCurrentSlide = async () => {
      const { format } = await import('date-fns')
      if (selectedDate && !selectedTime) return moveTo(1)
      if (selectedDate && selectedTime) {
        // selectedTime contains both date & time, selectedDate is redundant at this step
        localStorage.setItem('selectedTime', format(selectedTime, 'dd.MM.yyyy kk:mm'))
        return moveTo(2)
      }
    }

    keepTrackOfCurrentSlide()
  }, [selectedDate, selectedTime])

  // Ref Definitions
  const motionDirection = useRef(1)

  // Move to next or previous step
  function moveTo(targetIndex: number) {
    // motionDirection = 1 if user switches to next step, -1 if they go back one step
    motionDirection.current = 0 < targetIndex - step ? 1 : -1
    setStep(clamp(targetIndex))
  }
  const getStatus = (index: number, currentStepIndex: number) => {
    if (currentStepIndex > index) return 'complete'
    if (currentStepIndex === index) return 'current'
    if (currentStepIndex < index) return 'upcoming'
  }

  // Constant Definitions
  const steps = [
    { name: 'Step 1', status: getStatus(0, step) as string },
    { name: 'Step 2', status: getStatus(1, step) as string },
    { name: 'Step 3', status: getStatus(2, step) as string },
  ]

  return (
    <section className='w-full relative overflow-hidden min-h-screen sm:flex justify-center items-center'>
      <div className='hidden sm:flex items-start md:items-center sm:absolute sm:inset-0' aria-hidden='true'>
        <div className='w-[400px] pt-28 md:pt-0 md:w-[600px] opacity-20 blur-[1px] select-none'>
          <Image
            width={1000}
            height={1000}
            quality={10}
            src='/tooth-low-poly.jpg'
            className='pointer-events-none'
            alt='Praxis am Bulkamp Zahn Dekoration Element'
            aria-hidden={true}
          />
        </div>
      </div>
      <div className='relative pt-6 pb-16 sm:pb-24'>
        <main className='mt-16 sm:mt-24'>
          <div className='mx-auto max-w-7xl'>
            <div className='lg:grid lg:grid-cols-12 lg:gap-8'>
              <div className='px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center'>
                <div>
                  <Link prefetch={false} passHref={true} href={ROUTES.LOGIN}>
                    <a className='inline-flex border border-gray-200 shadow-sm items-center text-gray-800 bg-gray-50 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-black'>
                      <span className='px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-600 rounded-full'>
                        Termine
                      </span>
                      <span className='ml-4 text-sm'>Jetzt unkompliziert vereinbaren.</span>
                      <HiChevronRight className='ml-2 w-5 h-5 text-gray-500' aria-hidden='true' />
                    </a>
                  </Link>
                  <h1 className='mt-4 text-4xl tracking-tight font-bold text-black sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl'>
                    <span className='md:block'>Ihre Zahnärzte am</span>{' '}
                    <span className='text-indigo-600 md:block'>Bulkamp</span>
                  </h1>
                  <p className='mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                    Wir heißen Sie seit 2016 in unserer Praxis willkommen - technisch hochmodern und mit
                    Wohlfühlfaktor eingerichtet. So können wir Sie sorgfältig, individuell und einfühlsam
                    behandeln.
                  </p>
                  <p className='mt-8 text-sm text-black uppercase tracking-wide font-semibold sm:mt-10'>
                    In Kooperation mit
                  </p>
                  <div className='mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0'>
                    <div className='flex justify-start sm:justify-center lg:justify-start'>
                      <div className='flex flex-wrap justify-start sm:justify-center gap-12 px-1'>
                        <div className='relative w-14 h-9 grayscale transition hover:grayscale-0'>
                          <Image
                            src='/dgzmk-logo.jpg'
                            quality={10}
                            layout='fill'
                            objectFit='contain'
                            alt='Praxis am Bulkamp Partner DGZMK'
                          />
                        </div>
                        <div className='relative w-40 h-9 grayscale transition hover:grayscale-0'>
                          <Image
                            src='/dgzs-logo.jpg'
                            quality={10}
                            layout='fill'
                            objectFit='contain'
                            alt='Praxis am Bulkamp Partner DGZS'
                          />
                        </div>
                        <div className='relative w-14 h-9 grayscale transition hover:grayscale-0'>
                          <Image
                            src='/dgparo-logo.jpg'
                            quality={10}
                            layout='fill'
                            objectFit='contain'
                            alt='Praxis am Bulkamp Partner DGPARO'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-16 sm:mt-24 lg:mt-0 lg:col-span-6'>
                <div className='bg-gray-50 border border-gray-200 shadow-sm sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden'>
                  <div className='relative w-full px-4 py-8 sm:px-10 sm:min-h-[520px] sm:grid sm:content-center pt-10'>
                    {step !== 0 && (
                      <div className='flex justify-center pb-6'>
                        <button
                          onClick={() => moveTo(step - 1)}
                          type='button'
                          className='sm:absolute sm:top-8 sm:left-10 text-sm text-gray-400 flex gap-1 items-center'>
                          <HiArrowLeft className='font-thin' /> Zurück
                        </button>
                      </div>
                    )}
                    <AnimatePresence>
                      <div>
                        <motion.div key={step} {...getStepAnimationProps(motionDirection.current)}>
                          {step === 0 && (
                            <div className='w-full flex justify-center'>
                              <DynamicCalendar />
                            </div>
                          )}
                          {step === 1 && <DynamicTimes />}
                          {step === 2 && <DynamicLoginForm />}
                        </motion.div>
                      </div>
                    </AnimatePresence>
                  </div>
                  <div className='px-4 py-6 bg-gray-100 border-t-2 border-gray-200 sm:px-10 flex justify-center items-center'>
                    {/**
                     *
                     * Steps
                     *
                     */}
                    <DynamicProgressCircles
                      currentIndex={step}
                      motionDirection={motionDirection.current}
                      steps={steps}
                      moveTo={moveTo}
                    />

                    {/* <p className='text-xs leading-5 text-gray-500'>
                      Bitte sehen Sie sich vor der Anmeldung unsere Informationen zum{' '}
                      <a href='#' className='font-medium text-gray-900 hover:underline'>
                        Datenschutz
                      </a>{' '}
                      und zu{' '}
                      <a href='#' className='font-medium text-gray-900 hover:underline'>
                        Cookies
                      </a>{' '}
                      an.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </section>
  )
}

export default ComponentWrapper(Hero, 'hero', 'bg-white')
