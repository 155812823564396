import NavigationDots from '../NavigationDots'

const ComponentWrapper = (Component: React.ElementType, idName: string, className: string) =>
  function HOC() {
    return (
      <div id={idName} className={`maindiv relative w-full min-h-[70vh] flex items-center ${className}`}>
        <Component />
        <NavigationDots active={idName} />
      </div>
    )
  }

export default ComponentWrapper
