import type { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Hero from '../components/homepage/Hero/Hero'

const DynamicHero = dynamic(() => import('../components/homepage/Hero/Hero'))
const DynamicServices = dynamic(() => import('../components/homepage/Services'))
const DynamicOffice = dynamic(() => import('../components/homepage/Office'))
const DynamicTestimonial = dynamic(() => import('../components/homepage/Testimonial'))
const DynamicFooter = dynamic(() => import('../components/homepage/Footer'))

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Home - Praxis am Bulkamp</title>
        <meta name='description' content='Zahnärzte Praxis am Bulkamp' />
        <link rel='icon' href='/logo-without-text.png' />
      </Head>

      {/* <div>index</div> */}
      <Hero />
      <DynamicServices />
      <DynamicOffice />
      <DynamicTestimonial />
      <DynamicFooter />
    </>
  )
}

export default Home
